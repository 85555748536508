import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b882a35a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "toolbar-wrapper"
}
const _hoisted_2 = { class: "toolbar-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DetailPanel = _resolveComponent("DetailPanel")!
  const _component_UrgeButton = _resolveComponent("UrgeButton")!
  const _component_CancelButton = _resolveComponent("CancelButton")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'applyDetail-wrapper': true,
      'has-toolbar': _ctx.statusCode === _ctx.ProcessType.APPROVE,
    })
  }, [
    _createVNode(_component_DetailPanel, {
      signetType: "default",
      title: "申请认息"
    }),
    (_ctx.statusCode === _ctx.ProcessType.APPROVE)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_UrgeButton),
            _createVNode(_component_CancelButton, {
              disabled: !_ctx.allowCancel
            }, null, 8, ["disabled"])
          ])
        ]))
      : _createCommentVNode("", true)
  ], 2))
}