
import { defineComponent, ref } from "vue";
import { getUrlParams, store } from "@/utils";
import fetchApi from "@/api";
import ProcessType from "@/enums/processType";
import DetailPanel from "../detail/Index.vue";
import { ApplyItemProps } from "../type";
import { CancelButton, UrgeButton } from "./buttons";
import "../ApproveDetail/index.less";

export default defineComponent({
  name: "ApplyDetail",
  components: { DetailPanel, CancelButton, UrgeButton },
  props: {},
  setup() {
    const search = getUrlParams();
    const reqId = search.get("processInstanceId") || "";
    const allowCancel = ref(false);
    const statusCode = ref(ProcessType.APPROVE);

    const handleTypeInfo = (data: ApplyItemProps) => {
      allowCancel.value = data.allowCancel;
      statusCode.value = data.statusCode;
    };

    const taskItem = store.get("taskItem") as unknown as ApplyItemProps;
    if (!taskItem) {
      fetchApi
        .getApplyList()
        .data({ processInstanceIds: reqId, pageSize: 1, pageNo: 1 })
        .send("POST")
        .then((res) => {
          const data: { result: ApplyItemProps[] } = JSON.parse(res.data);
          if (data.result.length > 0) {
            store.set("taskItem", data.result[0]);
            handleTypeInfo(data.result[0]);
          }
        });
    } else {
      handleTypeInfo(taskItem);
    }
    return { allowCancel, statusCode, ProcessType };
  },
});
